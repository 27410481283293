const TIPOS_EXAME = [
  { value: 1, text: 'Admissional' },
  { value: 2, text: 'Demissional' },
  { value: 3, text: 'Periódico' },
  { value: 4, text: 'Mudança de Função' },
  { value: 5, text: 'Retorno ao Trabalho' },
]

const CLINICA_FORMA_PAGAMENTO = [
  { value: 1, text: 'Avulsa' },
  { value: 2, text: 'Mensal' },
]

const MODALIDADE = [
  { value: 'presencial', text: 'Presencial' },
  { value: 'online', text: 'Online' },
]

const STATUS_CLINICA = [
  { text: 'Todas', value: null },
  { text: 'Ativa', value: 'ativo' },
  { text: 'Em análise', value: 'analise' },
  { text: 'Inativa', value: 'inativo' },
  { text: 'Em recesso', value: 'recesso' },
]

const STATUS_CONTRATO = [
  { text: 'Vencido', value: 'vencido' },
  { text: 'A Vencer', value: 'vencer' },
]

const STATUS_ASO = [
  { value: 1, text: 'Agendado', color: 'primary' },
  { value: 2, text: 'Em Análise', color: 'primary' },
  { value: 3, text: 'Finalizado', color: 'success' },
  { value: 4, text: 'Inativo', color: 'bluegreylight' },
  { value: 5, text: 'Não Compareceu', color: 'info' },
  { value: 6, text: 'Aguardando', color: 'warning' },
  { value: 7, text: 'Atrasado', color: 'error' },
  { value: 8, text: 'Em aberto', color: 'purple' },
  { value: 9, text: 'Incompleto', color: 'greydarken' },
  { value: 10, text: 'Aguardando Complementares', color: 'deep-purple' },
  { value: 11, text: 'Aguardando Finalização', color: '#004D40' },
  { value: 12, text: 'Aguardando Clínico', color: 'teal' },
]

const STATUS_FUNCIONARIO = [
  {
    label: 'Apto',
    color: 'green',
    value: 1,
  },
  {
    label: 'Desligado',
    color: 'red',
    value: 2,
  },
  {
    label: 'Afastado',
    color: 'MediumSlateBlue',
    value: 3,
  },
  {
    label: 'INSS',
    color: 'LightCoral',
    value: 4,
  },
  {
    label: 'A Vencer',
    color: 'OrangeRed',
    value: 5,
  },
  {
    label: 'Vencido',
    color: 'Orange',
    value: 6,
  },
  {
    label: 'Pendente',
    color: 'lightgrey',
    value: 7,
  },
]

const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19[0-8][0-9]|199[0-9]|20[0-9]{2}|2100)/

const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const oneMegaByteStringSize = 1048576

const TIPOS_ASSINANTES = {
  testemunha: 'Testemunha',
  parte: 'Parte',
  'representante-legal': 'Representante Legal',
}

const unidadesMedida = [
  { text: 'Dose diária de ruído', value: '1' },
  { text: 'Decibel linear (dB (linear))', value: '2' },
  { text: 'Decibel (C) (dB(C))', value: '3' },
  { text: 'Decibel (A) (dB(A))', value: '4' },
  { text: 'Metro por segundo ao quadrado (m/s2)', value: '5' },
  { text: 'Metro por segundo elevado a 1,75 (m/s1,75)', value: '6' },
  { text: 'Parte de vapor ou gás por milhão de partes de ar contaminado (ppm)', value: '7' },
  { text: 'Miligrama por metro cúbico de ar (mg/m3)', value: '8' },
  { text: 'Fibra por centímetro cúbico (f/cm3)', value: '9' },
  { text: 'Grau Celsius (ºC)', value: '10' },
  { text: 'Metro por segundo (m/s)', value: '11' },
  { text: 'Porcentual', value: '12' },
  { text: 'Lux (lx)', value: '13' },
  { text: 'Unidade formadora de colônias por metro cúbico (ufc/m3)', value: '14' },
  { text: 'Dose diária', value: '15' },
  { text: 'Dose mensal', value: '16' },
  { text: 'Dose trimestral', value: '17' },
  { text: 'Dose anual', value: '18' },
  { text: 'Watt por metro quadrado (W/m2)', value: '19' },
  { text: 'Ampère por metro (A/m)', value: '20' },
  { text: 'Militesla (mT)', value: '21' },
  { text: 'Microtesla (μT)', value: '22' },
  { text: 'Miliampère (mA)', value: '23' },
  { text: 'Quilovolt por metro (kV/m)', value: '24' },
  { text: 'Volt por metro (V/m)', value: '25' },
  { text: 'Joule por metro quadrado (J/m2)', value: '26' },
  { text: 'Milijoule por centímetro quadrado (mJ/cm2)', value: '27' },
  { text: 'Milisievert (mSv)', value: '28' },
  { text: 'Milhão de partículas por decímetro cúbico (mppdc)', value: '29' },
  { text: 'Umidade relativa do ar (UR (%))', value: '30' },
  { text: 'Índice de Bulbo Úmido e Temperatura de Globo (IBUTG)', value: '31' },
]

export {
  TIPOS_EXAME,
  MODALIDADE,
  CLINICA_FORMA_PAGAMENTO,
  STATUS_ASO,
  STATUS_FUNCIONARIO,
  dateRegex,
  estados,
  oneMegaByteStringSize,
  unidadesMedida,
  TIPOS_ASSINANTES,
  STATUS_CLINICA,
  STATUS_CONTRATO,
}
